import api from "@/api";
import { ListOpions } from "@/models/type";
import { UserBindRow } from "@/store/modules/user";
import { debounceClick } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import { ElFormItemContext } from "element-plus/lib/el-form";
import { Vue } from "vue-class-component";
import MineSitePassword from './components/password'
import MineSiteEmail from './components/email'
import MineSitePhone from './components/phone'
import './index.scss'
import { UmsAddress } from "@/views/address";

export default class MineSite extends Vue {
  private userForm: UmsMemberParam = {
    id: 0,
    username: '',
    nickname: '',
    enName: '',
    gender: 1,
    birthday: '',
    cardId: '',
    city: '',
    detailArea: ''
  }
  private userBind: Array<UserBindRow> = []
  private threeList: Array<string> = ['facebook', 'wechat', 'google']
  private bindList: Array<ListOpions> = [
    { name: '手机号', value: 'phone' },
    { name: '电邮', value: 'email' },
    { name: '密码', value: 'password' },
  ]
  private formRules: any = {
    birthday: [
      { required: true, message: '请输入出生年月', trigger: 'change' }
    ]
  }

  public addressObj: UmsAddress = {}

  public passwordShow = false
  public emailShow = false
  public phoneShow = false

  public phoneType = ''
  public emailType = ''


  submitForm(): void {
    const __form = this.$refs.form as ElFormItemContext
    __form.validate(v => {
      if (v) {
        const form = Object.assign({}, this.userForm)
        api.sso_edit_info(form).then(res => {
          ElNotification({
            type: 'success',
            message: '保存成功'
          })
          this.getInfo()
        })
      } else {
        return false
      }
    })
  }

  getInfo(): void {
    api.sso_info().then(res => {
      const { info, bind } = res.data
      this.$store.dispatch('setUserInfo', info)
      this.$store.dispatch('setUserBind', bind)
      Object.keys(info).forEach((item: string) => {
        this.userForm[item] = info[item]
      })
      this.userBind = bind
    })
  }

  getAddress(): void {
    api.memberAddressList().then(res => {
      this.addressObj = res.data.find((item: UmsAddress) => item.defaultStatus === 1)
    })
  }

  unbind(source: string): void {
    ElMessageBox.confirm('确定解绑？', '解绑', {
      confirmButtonText: '解绑',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      api.sso_unbind({source}).then(res => {
        ElNotification({
          type: 'success',
          message: '解绑成功'
        })
        this.getInfo()
      })
    }).catch(() => false)
  }

  bind(source: string): void {
    const url = window.location.origin + window.location.pathname
    api.homeRender({ source, url: `${url};${url};${this.userForm.id}` }).then(res =>{
      window.location.replace(res.data)
    })
  }

  submitEdit(source: string): void {
    switch (source) {
      case 'phone':
        this.phoneType = 'edit'
        this.phoneShow = true
        break
      case 'email':
        this.emailType = 'edit'
        this.emailShow = true
        break
      case 'password':
        this.passwordShow = true
        break
      default:
        break
    }
  }
  submitBind(source: string): void {
    switch (source) {
      case 'phone':
        this.phoneType = 'bind'
        this.phoneShow = true
        break
      case 'email':
        this.emailType = 'bind'
        this.emailShow = true
        break
      case 'password':
        this.passwordShow = true
        break
      default:
        break
    }
  }


  emailClose(): void {
    this.emailShow = false
    this.getInfo()
  }

  phoneClose(): void {
    this.phoneShow = false
    this.getInfo()
  }

  logout(): void {
    ElMessageBox.confirm('确定退出登录？', '退出登录', {
      type: 'warning'
    }).then(() => {
      this.$store.dispatch('resetToken')
      this.$router.go(0)
      if(document.body.clientWidth < 900) {
        // TODO 跳转的mobile
        window.location.href = `http://mobile.taosseract.com/?removeToken=1`
      }
    }).catch(() => false)
  }

  render(): JSX.Element {

    const slot: any = {
      facebook: {
        label: () => <div class="mine_site-lable">
          <div class="mine_site-lable-img facebook" />
          <span class="mine_site-lable-text">Facebook</span>
        </div>
      },
      wechat: {
        label: () => <div class="mine_site-lable">
          <div class="mine_site-lable-img wechat" />
          <span class="mine_site-lable-text">Wechat</span>
        </div>
      },
      google: {
        label: () => <div class="mine_site-lable">
          <div class="mine_site-lable-img google" />
          <span class="mine_site-lable-text">Google</span>
        </div>
      }
    }

    return <div class="mine_site">
      <el-form
          ref="form"
          class="mine_site-basic"
          model={this.userForm}
          rules={this.formRules}
          label-width="100px"
          label-position="left"
        >
        <div class="mine_site-card">
          <h4>基本资料</h4>
          <el-row gutter={50}>
            <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
              <el-form-item label="用户名" prop="username">
                <el-input v-model={this.userForm.username} clearable placeholder="用户名" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row gutter={50}>
            <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
              <el-form-item label="中文名" prop="nickname">
                <el-input v-model={this.userForm.nickname} clearable placeholder="中文名" />
              </el-form-item>
            </el-col>
            <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
              <el-form-item label="英文名" prop="enName">
                <el-input v-model={this.userForm.enName} clearable placeholder="英文名" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row gutter={50}>
            <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
              <el-form-item label="用户名" prop="gender">
                <el-radio-group v-model={this.userForm.gender}>
                  <el-radio label={1}>男</el-radio>
                  <el-radio label={2}>女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row gutter={50}>
            <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
              <el-form-item label="出生年月" prop="birthday">
                <el-date-picker
                  v-model={this.userForm.birthday}
                  type="month"
                  placeholder="出生年月"
                  clearable
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="身份证号" prop="cardId">
                <el-input v-model={this.userForm.cardId} clearable placeholder="身份证号" />
              </el-form-item>
            </el-col>
            <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
              <el-form-item label="收货地址" prop="nickname">
                <div class="mine-address">
                  <div class="mine-address-header">
                    <div class="mine-address-header-name">{this.addressObj?.name}</div>
                    <div class="mine-address-header-phoneNumber">{this.addressObj?.phoneNumber}</div>
                    <div class="mine-address-header-edit" onClick={() => this.$router.push({name: 'Address', query: {back: 'MineSite'}})}>切换</div>
                  </div>
                  <p>{this.addressObj?.province} {this.addressObj?.city} {this.addressObj?.region} {this.addressObj?.detailAddress}</p>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row gutter={50}>
            <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
              <el-form-item label="我的地址" prop="city">
                <el-input v-model={this.userForm.city} clearable placeholder="我的地址" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row gutter={50}>
            <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
              <el-form-item label="详细地址" prop="detailArea">
                <el-input type="textarea" v-model={this.userForm.detailArea} clearable style="width: 100%" placeholder="详细地址" />
              </el-form-item>
            </el-col>
          </el-row>
          <div class="mine_site-submit">
            <div class="mine_site-submit-btn" style="margin-right: 20px" onClick={this.logout}>退出登录</div>
            <div class="mine_site-submit-btn" onClick={() => debounceClick(this.submitForm)}>保存</div>
          </div>
        </div>
        <div class="mine_site-card">
          <h4>账户信息</h4>
          { this.bindList.map(i => <el-row gutter={50}>
            <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
              <el-form-item label={i.name} prop={i.value}>
                {
                  i.value === 'password'
                    ? <span>**********</span>
                    : <span>{ this.userForm[i.value] || '暂未绑定' }</span>
                }
              </el-form-item>
            </el-col>
            <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
              <div class="mine_site-submit">
                {
                  this.userForm[i.value]
                    ? <div class="mine_site-submit-btn" onClick={() => debounceClick(() => this.submitEdit(i.value as string))}>修改</div>
                    : <div class="mine_site-submit-btn" onClick={() => debounceClick(() => this.submitBind(i.value as string))}>绑定</div>
                }
              </div>
            </el-col>
          </el-row>) }
          { this.threeList.map(i => <el-row gutter={50}>
            <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
              <el-form-item v-slots={slot[i]} label-width="150px" >
                { this.userBind.find(item => item.source === i)
                  ? <span style="color: #9DA4A7">已绑定</span>
                  : <span>未绑定</span>
                }
              </el-form-item>
            </el-col>
            <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
              <div class="mine_site-submit">
                {
                  this.userBind.find(item => item.source === i)
                    ? <div class="mine_site-submit-unbtn" onClick={() => debounceClick(() => this.unbind(i))}>解绑</div>
                    : <div class="mine_site-submit-btn" onClick={() => debounceClick(() => this.bind(i))}>绑定</div>
                }
              </div>
            </el-col>
          </el-row>)}
        </div>
      </el-form>
      <MineSitePassword id={this.userForm.id} show={this.passwordShow} onClose={() => this.passwordShow = false} />
      <MineSiteEmail type={this.emailType} show={this.emailShow} onClose={this.emailClose} />
      <MineSitePhone type={this.phoneType} show={this.phoneShow} onClose={this.phoneClose} />
    </div>
  }

  mounted(): void {
    this.getInfo()
    this.getAddress()
  }
}

export interface UmsMemberParam {
  birthday: Date | string
  cardId?: string
  city?: string
  detailArea?: string
  enName?: string
  gender?: number
  id: number
  nickname?: string
  username?: string
  [prop: string]: any
}
