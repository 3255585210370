import api from "@/api";
import { debounceClick } from "@/utils";
import { defineComponent, reactive, toRefs, ref } from "@vue/runtime-core";
import { nextTick } from "@vue/runtime-dom";
import { ElNotification } from "element-plus";
import { ElFormItemContext } from "element-plus/lib/el-form";
import Hcaptcha from '@/layout/components/Hcaptcha.vue'

export default defineComponent({
  name: 'MineSitePhone',
  props: {
    type: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    onClose: Function
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { show, type } = toRefs(props)
    const formRef = ref({})
    const phoneForm = reactive({
      phone: '',
      code: ''
    })

    // 设置一个验证码标识数
    const authSum = ref(0) // 0 - 60 s
    const timer = ref({}) // 定时器

    const isHcaptcha = ref(false)

    const phoneRules = {
      phone: [
        {required: true, message: '请输入手机号'}
      ],
      code: [
        {required: true, message: '请输入验证码'}
      ],
    }

    function close(): void {
      nextTick(() => {
        const __form = formRef.value as any
        __form.resetFields()
        emit('close')
      })
    }

    function submit(): void {
      nextTick(() => {
        const __form = formRef.value as ElFormItemContext
        __form.validate(v => {
          if(v) {
            const obj = Object.assign({}, phoneForm) as UpdatePhone
            api.editPhone(obj).then(() => {
              ElNotification({
                type: 'success',
                message: '修改手机号成功'
              })
              close()
            })
          }else {
            return false
          }
        })
      })
    }

    const authCode = {
      suffix: () => authSum.value
        ? <span class="login_register-auth">{authSum.value}秒后重新发送</span>
        : <span class="login_register-auth" onClick={() => debounceClick(hcaptchaChange)}>发送验证码</span>
    }

    function hcaptchaChange() {
      if(!phoneForm.phone) {
        ElNotification({
          type: 'warning',
          message: '请输入手机号或者电邮'
        })
        return
      }
      isHcaptcha.value = true
    }

    function authIntever(captchaResponse: string): void {
      // 发送验证码
      api.sendGetCode({code: phoneForm.phone, captchaResponse}).then(res => {
        ElNotification({
          type: 'success',
          message: '发送成功'
        })
        isHcaptcha.value = false
        if(authSum.value === 0) {
          authSum.value = 60
          clearInterval(timer.value as any)
          timer.value = setInterval(() => {
            authSum.value--
            !authSum.value && clearInterval(timer.value as any)
          }, 1000)
        }
      })
    }

    function onVerify(token: string): void {
      authIntever(token)
    }

    return () => <el-dialog
      title={type.value === 'edit' ? '修改手机号' : '绑定手机号'}
      v-model={show.value}
      width="500px"
      before-close={close}
      custom-class="mine_site-dialog"
    >
      {
        isHcaptcha.value
          ? <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Hcaptcha
                onVerify={onVerify}
              />
            </div>
          : <>
              <el-form
                ref={ formRef }
                model={phoneForm}
                rules={phoneRules}
                label-width="120px"
                label-position="top"
              >
                <el-form-item label={`${type.value === 'edit' ? '新' : ''}手机号`} prop="phone">
                  <el-input v-model={phoneForm.phone} clearable placeholder="请输入手机号"/>
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                  <el-input v-model={phoneForm.code} clearable placeholder="验证码" v-slots={authCode}/>
                </el-form-item>
              </el-form>
              <div class="mine_site-dialog-submit" onClick={submit}>确定修改</div>
            </>
      }
    </el-dialog>
  }
})

export interface UpdatePhone {
  code: string
  phone: string
}

